import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
  user: {},
};

const changeState = (state = initialState, { type, ...rest }) => {
  // console.log("store changeState state",state);
  // console.log("store changeState type",type);
  // console.log("store changeState rest",rest);
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "set_user":
      // console.log("store changeState case set_user");
      return { ...state, ...rest };
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, changeState);

export default () => {
  let store = createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};

// const store = createStore(changeState);

// export default store;
