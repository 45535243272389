import React, { Component } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import 'antd/dist/antd.css';
import TokenPage from './pages/token';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Login
const Login = React.lazy(() => import('./pages/login/Login'));

class App extends Component {
  render() {
    return (
      <div className="local-font">
        <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route  path="/home" name="Home" render={props => <TheLayout {...props} />} />
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/" render={() => (<Redirect to="/login"/>)}/>
          </Switch>
        </React.Suspense>
      </HashRouter>
      </div>

    );
  }
}

export default App;
