import Swal, { swal } from "sweetalert2/dist/sweetalert2.js";
import { useTranslation } from 'react-i18next';
const AlertError = (title = "ผิดพลาด", text) => {
    const { t, i18n } = useTranslation();

    return Swal.fire({
        title: title,
        text: text,
        icon: "error",
        confirmButtonText: t("okbtn"),
    });
}

export default AlertError