import axios from "axios";
import { getLocalStorage } from "./localStorage";
// check if an element exists in array using a comparer function
// comparer : function(currentElement)
Array.prototype.inArray = function (comparer) {
  for (var i = 0; i < this.length; i++) {
    if (comparer(this[i])) return true;
  }
  return false;
};

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.pushIfNotExist = function (element, comparer) {
  if (!this.inArray(comparer)) {
    this.push(element);
  }
};

export const setHeaderAuth = async () => {
  const token = await getLocalStorage("token");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export function checkFormatIdCard(p_iPID) {
  let isSuccess = true;
  var total = 0;
  var iPID;
  var chk;
  var Validchk;
  iPID = p_iPID.replace(/-/g, "");
  Validchk = iPID.substr(12, 1);
  var j = 0;
  var pidcut;
  for (var n = 0; n < 12; n++) {
    pidcut = parseInt(iPID.substr(j, 1));
    total = total + pidcut * (13 - n);
    j++;
  }

  chk = 11 - (total % 11);

  if (chk == 10) {
    chk = 0;
  } else if (chk == 11) {
    chk = 1;
  }
  if (chk == Validchk) {
    isSuccess = true;
  } else {
    isSuccess = false;
  }
  return isSuccess;
}

export function autoTabRazor(obj) {
  /* กำหนดรูปแบบข้อความโดยให้ _ แทนค่าอะไรก็ได้ แล้วตามด้วยเครื่องหมาย 
  หรือสัญลักษณ์ที่ใช้แบ่ง เช่นกำหนดเป็น  รูปแบบเลขที่บัตรประชาชน 
  4-2215-54125-6-12 ก็สามารถกำหนดเป็น  _-____-_____-_-__ 
  รูปแบบเบอร์โทรศัพท์ 08-4521-6521 กำหนดเป็น __-____-____ 
  หรือกำหนดเวลาเช่น 12:45:30 กำหนดเป็น __:__:__ 
  ตัวอย่างข้างล่างเป็นการกำหนดรูปแบบเลขบัตรประชาชน 
  */
  var pattern = new String("___-_______-__"); // กำหนดรูปแบบในนี้
  var pattern_ex = new String("-"); // กำหนดสัญลักษณ์หรือเครื่องหมายที่ใช้แบ่งในนี้
  var returnText = new String("");
  var obj_l = obj.length;
  var obj_l2 = obj_l - 1;
  for (var i = 0; i < pattern.length; i++) {
    if (obj_l2 == i && pattern.charAt(i + 1) == pattern_ex) {
      returnText += obj + pattern_ex;
      obj = returnText;
    }
  }
  if (obj_l >= pattern.length) {
    obj = obj.substr(0, pattern.length);
  }
  console.log(obj);
  return obj;
}

export function statusSurveyText(item) {
  if (
    item.isconclude === "O" &&
    item.date_balance < 0 &&
    item.public_status === "P"
  ) {
    return "ปิดรับฟัง";
  } else if (
    item.isconclude === "O" &&
    item.date_balance < 0 &&
    item.public_status !== "P"
  ) {
    return "ปิดรับฟัง (ไม่ได้เผยแพร่)";
  }
  if (item.isconclude !== "O") {
    if (item.public_status !== "P") {
      return "ปิดรับฟัง (ไม่ได้เผยแพร่)";
    }
    if (item.date_balance >= 0) {
      return "ปิดรับฟัง ก่อนกำหนด";
    }
    return "ปิดรับฟัง";
  } else {
    if (item.public_status == "P") {
      if (item.date_balance < 0) {
        return "ปิดรับฟัง";
      }
      return "เปิดรับฟัง";
    } else {
      return "รอการเผยแพร่";
    }
  }
}
