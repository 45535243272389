import axios from "axios";
import { Component } from "react";
import { setHeaderAuth } from "../utils";

export default class ApiUser extends Component {
  static login = async (data) => {
    const result = await axios({
      url: "mt_user/login",
      method: "post",
      data: data,
    });
    return result;
  };
  static get = async (id, country) => {
    var url = `mt_user/plant/${id}`;
    if(country){
        url = `mt_user/bc/${country}`
    }
    const result = await axios({
      url: url,
      method: "get",
    });
    return result;
  };
  static insert = async (data) => {
    const result = await axios({
      url: "mt_user",
      method: "post",
      data: data,
    });
    return result;
  };
  static update = async (data) => {
    const result = await axios({
      url: "mt_user",
      method: "put",
      data: data,
    });
    return result;
  };
  static delete = async (data) => {
    const result = await axios({
      url: `mt_user/${data.userid}`,
      method: "delete",
    });
    return result;
  };
}
