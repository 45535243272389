import axios from "axios";
import { WEB_API } from "../env";
axios.defaults.baseURL = WEB_API;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/";
    }
    throw error;
  }
);
